import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { animated, useSpring } from 'react-spring';
import { LazyImage } from 'react-lazy-images';
import { Link } from '@reach/router';
import { Album } from '../types';
import { slugify } from '../utilities/slugify';

import { ImgLoad, Placeholder } from './Animations';
import AlbumTitle from './AlbumTitle';
import ArtistTitle from './ArtistTitle';
import { ReactComponent as AppleMusic } from '../assets/social/apple_music.svg';
import { ReactComponent as Spotify } from '../assets/social/spotify.svg';

/*
- [ ] onclick, full page width / route to single album page?
*/

const ArticleWrapper = styled.article`
  position: relative;
  transform: translate3d(0, 0, 0);
`;

// hmm this isn't responsive...
const SVGWrapper = styled.div`
  position: absolute;
  bottom: 0.2em;
  right: 0.4em;
  & > svg {
    width: 1em;
    height: 1.1em;
    fill: #fff;
    opacity: 0.8;
    padding-left: 0.2em;
    padding-bottom: 1em;
  }
`;

const Side = styled(animated.div)`
  will-change: transform, opacity;
`;

function AlbumGridItem(album: Album): React.ReactElement {
  const { cover, artist, title, id } = album;
  const [flipped, setFlipped] = useState(false);
  // const [selected, setSelected] = useState(null);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(700px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 800, friction: 100, clamp: true }
  });

  const slug = slugify(title);

  const image = require(`../assets/images/covers/${cover}`);

  return (
    <ArticleWrapper className='w-50 w-20-l w-50-m w-50-ns' style={{}}>
      <Link to={slugify(title)}>
        <Side
          className='flex flex-wrap items-center'
          onClick={() => setFlipped(state => !state)}
          style={{
            opacity: opacity.interpolate(o => o),
            transform: transform.interpolate(t => `${t} rotateY(180deg)`),
            position: 'absolute',
            height: '100%',
            width: '100%'
          }}
        >
          <div className='w-100 tc'>
            <ArtistTitle title={artist} />
            <AlbumTitle title={title} />
          </div>

          <SVGWrapper className='w-100 tc f6 f3-l f3-m f5-ns '>
            <AppleMusic />
            <Spotify />
          </SVGWrapper>
        </Side>
        <Side
          onClick={() => setFlipped(state => !state)}
          style={{
            opacity: opacity.interpolate(o => 1.05 - Number(o)),
            transform: transform.interpolate(t => `${t}`)
          }}
        >
          <LazyImage
            src={image}
            alt={`${artist}`}
            // debounceDurationMs={500 + id * 200}
            placeholder={({ ref }) => (
              <div ref={ref} className=''>
                <Placeholder image='./images/placeholder500.png' />
              </div>
            )}
            actual={({ imageProps }) => (
              <ImgLoad alt={imageProps.alt} image={imageProps.src} />
            )}
          />
        </Side>
      </Link>
    </ArticleWrapper>
  );
}

export default AlbumGridItem;
