/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@reach/router';
import React, { useState, useReducer } from 'react';
import styled from 'styled-components/macro';
import { animated, useSpring } from 'react-spring';
import { IoIosMenu } from 'react-icons/io';

import Modal from './Overlay';

/*

# theme

## colors

nav-color:
nav-hover-color:
header-bg: 
logo-color: 
burger-color:
player-bg:
player-artist:
player-artist-underline:
player-song:
player-control:

## fonts

font-logo
font-player-arist
font-player-song
font-nav
font-body



*/

const HeaderWrapper = styled(animated.div)`
  position: sticky;
  background: hsla(230, 23.1%, 10%, 1); /* #14161f */
  background: #001524;
  background: hsla(205, 45.8%, 25.4%, 1);
  background: #fff;
  border-bottom: 1px solid #37474f;
  // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  font-family: 'worksanssemibold';
  text-decoration: none;
  letter-spacing: -0.03em;
  vertical-align: center;
  will-change: opacity, transform;
  // background: hsl(161.3, 35%, 56.1%);
  background: #212121;

  a {
    color: #fff;
  }

  .links {
    text-align: right;
    width: 80%;
  }
  .links > a {
    color: #fff;
    text-transform: uppercase;
  }
  .logo {
    display: inline-block;
    text-transform: lowercase;
    width: 20%;
    font-family: 'Rubik', 'Helvetica', sans-serif;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #ffdfdf;
    color: #fce4ec;
    color: #fff;
    color: #e0e0e0;
    //text-shadow: 0.5px 0.5px 2px hsla(205, 45.8%, 25.4%, 1),
    // 1px 1px 0px hsla(207.4, 40.9%, 5.4%, 0.4);
  }

  svg {
    --svg-size: 1.25em;
    width: var(--svg-size);
    height: var(--svg-size);
  }
  svg:hover {
    color: hsl(204, 30.7%, 40.7%);
    // background: hsla(205, 24.8%, 70.4%, 0.1);
  }
`;

type State = { shown: boolean };
type Action = { type: 'hide' } | { type: 'show' };

interface Context {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'hide':
      return { shown: false };
    case 'show':
      return { shown: true };
    default:
      return state;
  }
}

const dispatch = (): void => undefined;
const state = { shown: false };

export const ModalContext = React.createContext<Context>({
  state,
  dispatch
});

function Header(): React.ReactElement {
  const onload = useSpring({
    from: { opacity: 0, transform: 'translate3d(0%,-100%,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0%,0)' },
    config: { mass: 5, friction: 150, tension: 1000 },
    delay: 300
  });

  const [modalShown, dispatch] = useReducer(reducer, { shown: false });

  return (
    <HeaderWrapper
      className='flex justify-between items-center pa2 pv0 ph2 f5 f4-l'
      style={{
        ...onload
      }}
    >
      <Link className={`logo pa1`} to='/'>
        30
      </Link>
      <div className='links'>
        {/* <Link to='/albums'>albums</Link>&nbsp;&nbsp;
        <Link to='/albums/alopecia'>page</Link>&nbsp;&nbsp;
        <Link to='/albums/alopeciaz'>404</Link>&nbsp;&nbsp; */}
        <a
          href='#'
          onClick={() => {
            dispatch({ type: 'show' });
          }}
        >
          <IoIosMenu className='pa1' />
        </a>
      </div>
      <ModalContext.Provider
        value={{
          state: modalShown,
          dispatch: dispatch
        }}
      >
        <Modal />
      </ModalContext.Provider>
    </HeaderWrapper>
  );
}

export default Header;
