import React from 'react';
import styled from 'styled-components/macro';

const Title = styled.div`
  font-family: 'IBM Plex Mono';
  letter-spacing: -0.05em;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
`;

function ArtistTitle({ title }: { title: string }): React.ReactElement {
  return <Title className='f6 f4-ns f3-m f4-l'>{title}</Title>;
}

export default ArtistTitle;
