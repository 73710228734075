// from: https://codesandbox.io/s/1z0397wov3
// converted to typescript for my usage

import { useState, useEffect } from 'react';

export default function useKeyPress(targetKey: string): boolean {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }: { key: string }): void {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }

    // If released key is our target key then set to false
    function upHandler({ key }: { key: string }): void {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    }

    console.log('should be adding event listeners');
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      console.log('should be removing event listeners');
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
