import React from 'react';
import { animated, useSpring, config } from 'react-spring';

// export const LoadPlaceholder = React.forwardRef(({props}, ref) => {
//   const spring = useSpring({
//     from: { opacity: 0, filter: `blur(0px)` },
//     to: { opacity: 0.2, filter: `blur(0px)` },

//     delay: 500
//   });

//   return (
//     <animated.img
//       src={props.image}
//       style={{ ...spring, willChange: "opacity, filter" }}
//     />
//   );
// });

export function Placeholder({ image }) {
  const spring = useSpring({
    from: { opacity: 0, filter: 'blur(30px)' },
    to: { opacity: 0.5, filter: 'blur(0px) ' },
    delay: 0
  });

  return (
    <animated.div
      className='cover db aspect-ratio--1x1'
      style={{
        ...spring,
        backgroundImage: `url(${image})`,
        willChange: 'opacity'
      }}
    />
  );
}

export function ImgLoad({ image, alt }) {
  const spring = useSpring({
    from: { opacity: 0.3, filter: `blur(5px)` },
    to: { opacity: 1, filter: `blur(0px)` },
    config: config.stiff,
    delay: 300
  });

  return (
    <animated.div
      className='cover db aspect-ratio--1x1'
      alt={alt}
      style={{
        ...spring,
        backgroundImage: `url(${image})`,
        willChange: 'opacity, filter'
      }}
    />
    // <img src={image} />
    // </animated.div>
  );
}

export function GridImg({ image }) {
  const onLoad = useSpring({
    from: { opacity: 1, filter: `blur(5px)` },
    to: { opacity: 1, filter: `blur(0px)` },
    config: config.default,
    delay: 1000
  });

  return (
    <animated.img src={image} style={{ ...onLoad, willChange: 'opacity, filter' }} />
  );
}
