/* eslint-disable */
/* tx-a11y/anchor-is-valid */
import { Router } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import { albums, sluggedAlbums } from './data';

import AlbumCards from './containers/AlbumCards';
import AlbumGrid from './containers/AlbumGrid';
import AlbumsRoutes from './containers/AlbumsRoutes';
import Header from './components/Header';
import Player from './components/Player';
import Slider from './components/Slider';
import Modal from './components/Overlay';

const BodyWrapper = styled.div`
  // position: absolute;
  // width: 100vw;
  // overflow: auto;
  // height: 110vh;
  // padding-bottom: 9.5vh;
  // margin-bottom: 10vh;
  // will-change: transform, opacity;
  // height: 100%;
`;

function App() {
  return (
    <BodyWrapper>
      <Header />
      <Player />
      <Router primary={false}>
        <AlbumCards path='/' albums={albums} />
        <Slider path='/slider' />
        <AlbumsRoutes path='/albums/*' />
      </Router>
    </BodyWrapper>
  );
}

export default App;
