import React from 'react';
import { ImgLoad } from './Animations';
import { Album } from '../types';
import styled from 'styled-components';

const all = 'w-100 w-third-l w-third-m pa0-l pr3-l';
const meta = `${all} pa2 pa0-l pa3-m f6 pr3-l pr2-m`;

const PageWrapper = styled.div`
  background: #fff;
  // display: block;
  // overflow: hidden;
  padding-bottom: 10vh;
  // overflow-x: hidden;
  // overflow-y: hidden;
`;

// https://github.com/reach/router/issues/115
export default function AlbumPage({ album }: { album: Album }): React.ReactElement {
  const { title, artist, description, cover } = album;
  const image = require(`../assets/images/covers/${cover}`);
  return (
    <PageWrapper>
      <div className='flex flex-col flex-wrap'>
        <div className={all}>
          <ImgLoad alt={title} image={image} />
        </div>
        <div className={meta}>
          <h2>{title}</h2>
          <h3>{artist}</h3>
          <p>{description}</p>
        </div>
        <div className={meta}>
          <p>Services Links</p>
          <p>Play Now</p>
          <p>Play Next</p>
          <p>Play Later</p>
        </div>
      </div>
    </PageWrapper>
  );
}
