import { slugify } from './utilities/slugify';
import { Album } from './types';

export const albums: Album[] = [
  {
    id: 0,
    artist: 'Why?',
    title: 'Alopecia',
    cover: 'alopecia.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 1,
    artist: 'Frank Ocean',
    title: 'Channel Orange',
    cover: 'channel-orange.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 2,
    artist: 'Bon Iver',
    title: 'Bon Iver',
    cover: 'bon-iver.jpg',
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  },
  {
    id: 3,
    artist: 'Muscles',
    title: 'Guns Babes Lemonade',
    cover: 'guns-babes-lemonade.jpg',
    description:
      'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.'
  },
  {
    id: 4,
    artist: 'Kanye',
    title: 'Yeezus',
    cover: 'yeezus.jpg',
    description:
      'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.'
  },
  {
    id: 5,
    artist: 'Japandroids',
    title: 'Post Nothing',
    cover: 'post-nothing.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 6,
    artist: 'Born Ruffians',
    title: 'Red, Yellow and Blue',
    cover: 'red-yellow-and-blue.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 7,
    artist: 'The Streets',
    title: "A Grand Don't Come for Free",
    cover: 'a-grand-dont-come-for-free.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 8,
    artist: 'Tierra Whack',
    title: 'Whack World',
    cover: 'whack-world.jpg',
    description:
      "The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum."
  },

  {
    id: 9,
    artist: 'Girl Talk',
    title: 'Night Ripper',
    cover: 'night-ripper.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  }
];

export const fourAlbums = [
  {
    id: 0,
    artist: 'Why?',
    title: 'Alopecia',
    cover: 'alopecia.jpg',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 1,
    artist: 'Tierra Whack',
    title: 'Whack World',
    cover: 'whack-world.jpg',
    description:
      "The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum."
  },
  {
    id: 2,
    artist: 'Bon Iver',
    title: 'Bon Iver',
    cover: 'bon-iver.jpg',
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  },
  {
    id: 3,
    artist: 'Muscles',
    title: 'Guns Babes Lemonade',
    cover: 'guns-babes-lemonade.jpg',
    description:
      'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.'
  }
];

interface AlbumWithSlug extends Album {
  slug: string;
}

export const sluggedAlbums: AlbumWithSlug[] = [
  {
    id: 0,
    artist: 'Why?',
    title: 'Alopecia',
    cover: 'alopecia.jpg',
    get slug() {
      return slugify(this.title);
    },
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
  },
  {
    id: 3,
    artist: 'Muscles',
    title: 'Guns Babes Lemonade',
    cover: 'guns-babes-lemonade.jpg',
    get slug() {
      return slugify(this.title);
    },
    description:
      'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.'
  }
];
