import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { animated, useSpring, useTransition, config } from 'react-spring';
import { Link } from '@reach/router';
import { IoMdClose } from 'react-icons/io';

import { ModalContext } from './Header';
import useKeyPress from './use-key-press';

type Props = {
  children?: React.ReactChild | React.ReactChildren;
};

const ModalWrapper = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  height: 100vh;
  width: 100vw;

  z-index: 999;
  will-change: opacity, transform;
`;

const ModalInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // transform: translateZ(0);
  // -webkit-transform: translateZ(0);
  z-index: 998;
  justify-content: center;

  color: #00365d;

  a {
    align-items: center;
    justify-items: center;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt', 'Helvetica', sans-serif;
    font-family: 'Inconsolata';
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: -0.02em;
    transition: color 0.3s ease-out, border-color 0.6s ease-out;
    color: hsl(161.3, 45%, 60.1%);
    border-bottom: 2px solid transparent;
    color: #37474f;
  }

  a:hover {
    color: #f4ac45;
    border-bottom: 2px solid hsla(161.3, 26%, 66.1%, 0.3);
    color: hsl(161.3, 35%, 40.1%);
  }

  & > p {
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  & > button {
    background: transparent;
    border: 1px solid #fff;
    color: #00365d;
    position: absolute;
    top: 0.25em;
    right: 0.25em;
  }

  & > div {
  }

  svg {
    fill: #8d99ae;
  }
`;

const AnimationWrapper = styled.div`
  // transform: translateZ(0);
  // position: absolute;
  // z-index: 500;
  // transform: translateX(0);
  // transform-style: preserve-3d;
`;

// this is happening every render!
const modalRoot = document.getElementById('root') as HTMLElement;

function ModalPortal({ children }: Props): React.ReactElement {
  return ReactDOM.createPortal(children, modalRoot);
}

function Modal(): React.ReactElement | null {
  const { dispatch, state } = useContext(ModalContext);
  const escapePressed = useKeyPress('Escape');

  useEffect(() => {
    if (escapePressed) {
      dispatch({ type: 'hide' });
    }
  }, [escapePressed]);

  const transitions = useTransition(state.shown, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.98 },
    leave: { opacity: 0 },
    config: config.default

    // config: { mass: 5, tension: 500, friction: 10, clamp: true }
  });

  // const { opacity } = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 }
  //   // transform: `rotateY(${state.shown ? 180 : 0}deg)`,
  //   // config: { mass: 5, tension: 500, friction: 80 }
  // });

  // if (state.shown) {
  return (
    <ModalPortal>
      <>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <ModalWrapper key={key} style={props}>
                <ModalInner>
                  <Link to='/albums' onClick={() => dispatch({ type: 'hide' })}>
                    Albums
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to='/albums/alopecia'
                    onClick={() => dispatch({ type: 'hide' })}
                  >
                    Page
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to='/albums/alopeciaz'
                    onClick={() => dispatch({ type: 'hide' })}
                  >
                    About
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to='/albums/alopeciaz'
                    onClick={() => dispatch({ type: 'hide' })}
                  >
                    404
                  </Link>
                  {/* <p>About </p>
                  <p>Contact </p>
                  <p>Source Code</p> */}
                  <button onClick={() => dispatch({ type: 'hide' })}>
                    <IoMdClose size='1.5em' />
                  </button>
                </ModalInner>
              </ModalWrapper>
            )
        )}
      </>
    </ModalPortal>
    //   );
    // } else {
    //   return null;
    // }
  );
}

export default Modal;
