/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components/macro';
import { LazyImage } from 'react-lazy-images';
import { Album } from '../types';
import { ImgLoad, Placeholder } from './Animations';
import { ReactComponent as AppleMusic } from '../assets/social/apple_music.svg';
import { ReactComponent as Spotify } from '../assets/social/spotify.svg';

const AlbumTitle = styled.a`
  color: hsla(230, 23.1%, 17%, 1);
  line-height: 1.1em;
  font-weight: 600;
  font-family: 'Rubik';
  letter-spacing: -0.05em;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 2px dotted hsla(200.2, 35%, 25%, 0.3);
  border-bottom: 2px dotted hsla(200.2, 35%, 25%, 0.3);
  padding-bottom: 0.75rem;
  text-decoration: none;

  &:hover {
    background: #ffdfdf;
  }
`;

const ArtistTitle = styled.a`
  font-family: 'IBM Plex Mono';
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: hsla(230, 23.1%, 25%, 0.6);
  text-decoration: none;
  margin-bottom: 0rem;
`;

export default function AlbumCard(album: Album): React.ReactElement {
  const { cover, artist, title, description } = album;
  const image = require(`../assets/images/covers/${cover}`);
  return (
    <article className=' mt0 flex mr0 mb4-l mb2-ns mb3'>
      <div className='flex flex-column album-card ma-0 '>
        <div className=''>
          <div className='o-90 ph0'>
            <a className='db aspect-ratio aspect-ratio--1x1 ba bw0 b--black-10 link'>
              <LazyImage
                src={image}
                alt={`${artist} - {title}`}
                // below is an ugly hack
                // debounceDurationMs={900 + id * 200}
                placeholder={({ ref }) => (
                  <div ref={ref}>
                    <Placeholder image='./images/placeholder500.png' />
                  </div>
                )}
                actual={({ imageProps }) => (
                  // Replace this with version of grid image?
                  <ImgLoad alt={imageProps.alt} image={imageProps.src} />
                )}
              />
            </a>
          </div>

          <div className='mt3 ph3 mt4-m'>
            <ArtistTitle href='#' className='db f5 f5-m f4-l'>
              {artist}
            </ArtistTitle>
            <AlbumTitle href='#' className='mt1 ph0 ph0-ns db mb0 f2 f2-ns f2-m f2-l'>
              {title}
            </AlbumTitle>
          </div>
        </div>

        <div
          className='tl f6 f5-ns ph3 pl0 pa2 pt0 mt2 pt0-ns'
          style={{ lineHeight: '1.5em', fontFamily: 'Cardo' }}
        >
          {description}
        </div>

        <div className='pa2 ph3 pt1 pt2-ns'>
          <AppleMusic width='1.2em' height='1.2em' className='mr1' />
          <Spotify width='1.2em' height='1.2em' />
        </div>
      </div>
    </article>
  );
}
