/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components/macro';
import { Album } from '../types';

import AlbumCardItem from '../components/AlbumCardItem';

const GridWrapper = styled.section`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  color: hsl(200.2, 35%, 20%);
  padding-bottom: 10vh;
`;

type Props = {
  albums: Album[];
};

function AlbumCards(props: Props): React.ReactElement {
  const { albums } = props;
  const transitions = useTransition(albums, album => album.id, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
    trail: 100
  });

  return (
    <GridWrapper className='flex-l flex-row-l flex flex-wrap flex-row w-100 bg-white'>
      {transitions.map(({ item: album, key, props }) => (
        <animated.div
          key={key}
          className='w-100 w-third-m w-100-ns w-25-l'
          style={{ ...props, willChange: 'opacity' }}
        >
          <AlbumCardItem {...album} />
        </animated.div>
      ))}
    </GridWrapper>
  );
}

export default AlbumCards;
