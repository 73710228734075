import React from 'react';

import AlbumGrid from './AlbumGrid';
import AlbumPage from '../components/AlbumPage';
import { albums, sluggedAlbums } from '../data';

type AlbumsRouteProps = {
  ['*']?: string;
};

export default function AlbumsRoutes(props: AlbumsRouteProps): React.ReactElement {
  // if no wildcard match is found, return grid view
  if (props['*'] === '') {
    return <AlbumGrid albums={albums} />;
  }

  // otherwise, check if route param matches album, render that album
  const result = sluggedAlbums.find(album => album.slug === props['*']);
  if (result) {
    return <AlbumPage album={result} />;
  }

  // otherwise, render 404
  return <div className='w-100 justify-center db align-center'>404</div>;
}
