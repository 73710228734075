import React, { useState, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components/macro';

type Style = object;

const SliderDiv = styled.div`
  overflow: hidden;
  // width: 500px;
  // display: inline;

  padding: 0;
  margin: 0;
  & > div {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 25em;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }
`;

const pages = [
  ({ style }: { style: Style }) => (
    <animated.div
      style={{
        ...style,
        background: 'lightpink'
      }}
    >
      A
    </animated.div>
  ),
  ({ style }: { style: Style }) => (
    <animated.div style={{ ...style, background: 'lightblue' }}>B</animated.div>
  ),
  ({ style }: { style: Style }) => (
    <animated.div style={{ ...style, background: 'lightgreen' }}>C</animated.div>
  )
];

function Slider(): React.ReactElement {
  const [index, set] = useState(0);
  const onClick = useCallback(() => set(state => (state + 1) % 3), []);
  const transitions = useTransition(index, p => p, {
    from: {
      opacity: 0,
      width: '10%'
      // transform: 'translateX(100%)'
      // transformOrigin: '0% 0%'
    },
    enter: { opacity: 1, width: '50%', transform: 'translateX(0%)' },
    leave: { opacity: 0, width: '10%', transform: 'translateX(-100%)' }
  });

  return (
    <SliderDiv className='simple-trans-main' onClick={onClick}>
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item];
        return <Page key={key} style={{ ...props }} />;
      })}
    </SliderDiv>
  );
}

export default Slider;
