import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { IoIosPlay, IoIosFastforward, IoIosPause } from 'react-icons/io';

// icons:ac
// https://react-icons.netlify.com/#/icons/io

// colors:
// #EF3E36 deeo carmine pink
// #FFDFDF misty rose
// #00365D midnight blue
// #D693A3 parrot pink
// https://coolors.co/dad6d6-2e4052-ffdfdf-92bfb1-f4ac45

/*
- [ ] better controls
- [ ] song duration ui
- [ ] some kind of queue system
*/

/* 

state:

playerCurrent
playerQueue
playerPlaying

actions:

playerStart
playerStop
playerQueueNext
playerQueueLater
playerClear
playerNext
playerPrevious
playerRestart

*/

const PlayerWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  color: #001524;

  box-shadow: 0 4px 8px -4px hsla(205.9, 100%, 11%, 0.3);

  background: #fff;

  .player-bg {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    color: hsl(35.5, 75.8%, 95.8%);

    img {
      // flex: none;
      // display: inline;
      // float: left;
      width: 1em;
      height: 1em;
      display: none;
      visible: none;
      opacity: 1;
      // filter: saturate(0%);
    }
    & > div {
      margin-right: 0.75em;
    }
  }

  .song-artist {
    font-size: 60%;
    // padding-top: 0.2em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: 'Inconsolata';
    // text-decoration: underline;
    text-decoration-color: hsla(204.4, 22.1%, 62.7%, 0.3);
    font-weight: 400;
    color: hsl(204, 30.7%, 40.7%);
    color: #616161;
  }

  .song-title {
    font-size: 85%;
    letter-spacing: -0.02em;
    color: hsl(204, 30.7%, 30.7%);
    font-family: 'Cooper Hewitt', 'Helvetica', sans-serif;
    // font-family: 'Eczar', sans-serif;
    font-family: 'Rubik';
    color: hsl(20, 100%, 65.8%); // nice orange
    color: hsl(323, 80%, 65.8%);
    color: #ec407a;
    color: #92bfb1;
    color: #f4ac45;
    // color: hsla(205, 45.8%, 25.4%, 1);
    font-weight: 400;
    // color: hsl(161.3, 35%, 56.1%);
    // text-decoration: underline;
    text-decoration-color: hsla(204.4, 22.1%, 62.7%, 0.3);
    color: #212121;
  }

  .controls {
    display: flex;
    padding-top: 0.1em;
  }

  & button {
    flex-grow: 0;
    border: 0;
    background: transparent;
    padding: 0px;
    font-weight: 400;

    svg {
      // border-radius: 0.2em;
      // background: hsla(205, 24.8%, 47.4%, 0.1);
      // padding: 0.3em;
      fill: #5b7e97;
      fill: #8d99ae;
      padding: 0.25em;
      vertical-align: center;
      // align: center;
      // margin-left: 0.25em;
      --svg-size: 1em;
      width: var(--svg-size);
      height: var(--svg-size);
    }

    svg :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export default function Player(): React.ReactElement {
  let [timeElapsed, setTime] = useState(0);
  let [songTitle, setSongTitle] = useState('...');
  let [songArtist, setSongArtist] = useState('...');
  let [playing, setPlaying] = useState(false);
  let [started, setStarted] = useState(false);

  function togglePlaying(): void {
    setPlaying(!playing);
  }

  function resetTimer(): void {
    setStarted(false);
    setTime(0);
  }

  useEffect(() => {
    setSongTitle('Hungry Hippos');
    setSongArtist('Tierra Whack');
    setSongTitle("A Grand Don't Come for Free");
    if (started) {
      const timer = window.setInterval(() => {
        setTime(timeElapsed => timeElapsed + 1);
      }, 1000);
      return () => {
        window.clearInterval(timer);
      };
    } else {
      return;
    }
  });

  const image = require(`../assets/images/covers/whack-world.jpg`);

  return (
    <PlayerWrapper className='f4 br0 flex flex-col pa3 ph1 f5 f3-l'>
      <div className='w-80 player-bg'>
        <div>
          <img src={image} />
        </div>
        <div className='flex flex-column justify-around'>
          <span className='song-title'>{songTitle}</span>
          <span className='song-artist'>{songArtist}</span>
        </div>
      </div>
      <div className='w-20 flex controls align-center justify-end'>
        {!playing ? (
          <button className='' onClick={togglePlaying}>
            <IoIosPlay />
          </button>
        ) : (
          // pause
          <button onClick={togglePlaying}>
            <IoIosPause />
          </button>
        )}
        &nbsp;
        {/* next */}
        <button>
          <IoIosFastforward />
        </button>
        &nbsp;
        {timeElapsed !== 0 ? <button onClick={resetTimer}>RESET</button> : ''}
      </div>
    </PlayerWrapper>
  );
}
