/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import AlbumGridItem from '../components/AlbumGridItem';
import { Albums } from '../types';

/*
Overall this is working well, but:
* had to use set width/height to get flipping working using flex-box/abs positioning
* so:
* [x] rewrite with grid OR (fixed flex-box issue, no need to do this)
* [x] implement masonry react-spring (fixed flex-box issue, no need to do this)
* [ ] implement gradient backgrounds for flip
* [ ] consolidate styles (currently parents style children, be more declarative)
*/

function AlbumGrid({ albums }: Albums): React.ReactElement {
  return (
    <div
      className='flex flex-row flex-wrap'
      style={{
        // background: 'hsla(230, 15.1%, 20%, 1)',
        overflowX: 'hidden',
        paddingBottom: '10vh'
      }}
    >
      {albums.map(album => (
        <AlbumGridItem key={album.id} {...album} />
      ))}
    </div>
  );
}

export default AlbumGrid;
