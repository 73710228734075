import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Title = styled.div`
  color: #ffde37;
  line-height: 1.1em;
  padding-top: 0.2em;
  font-weight: 600;
  font-family: 'Rubik';
  margin-top: 0em;
  letter-spacing: -0.05em;
  text-decoration: none;
  margin-left: 1vw;
  margin-right: 1vw;
  -webkit-text-decoration: #f6fffe55 solid underline;
  text-decoration: #f6fffe55 solid underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink;
`;

function AlbumTitle({ title }: { title: string }): React.ReactElement {
  return <Title className='ph0 ph0-ns db mb0 f4 f2-ns f2-m f2-l'>{title}</Title>;
}

AlbumTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default AlbumTitle;
